.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.background-guy {
  height: 100vh;
  background-image: url('./img/Screen-Shot-2019-11-21-at-11.51.24-AM.png');
  background-position: 0px 0px;
  background-size: cover;
}

.taller-overlay {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 36vh;
  margin: 0px;
  border: 1px none #000;
  background-color: hsla(0, 0%, 100%, 0.8);
}

.logo-draft {
  width: 200px;
}

.qr-code {
  display: inline;
  width: auto;
  height: 180px;
}

.h1 {
  margin-bottom: 20px;
  background-color: #45dcff;
  font-family: Oswald, sans-serif;
  font-size: 40px;
  line-height: 30px;
}

.h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Arvo, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.qr-code-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
}

.right-column {
  display: inline-block;
  height: 100%;
}

.columns-outer {
  height: 100%;
}

.hero-text {
  height: auto;
  text-align: center;
}

.middle-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.left-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  width: 70px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 200px;
  background-color: #ffd62c;
}

.mobile-logo {
  width: 180px;
}

.heading {
  background-color: rgba(69, 220, 255, 0);
  font-family: Oswald, sans-serif;
  color: #fff;
  font-size: 40px;
}

.header {
  padding-top: 20px;
  padding-bottom: 50px;
}

.basic-paragraph {
  font-family: Arvo, sans-serif;
  line-height: 24px;
}

.basic-paragraph.light {
  color: hsla(0, 0%, 100%, 0.8);
}

.submit-button {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 20px;
  background-color: #000;
  font-family: Arvo, sans-serif;
  color: #45dcff;
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.checkbox-label {
  font-family: Arvo, sans-serif;
  font-size: 18px;
}

.checkbox-field {
  margin-top: 30px;
  margin-bottom: 30px;
}

.field-label {
  font-family: Arvo, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}

.text-field {
  height: 50px;
  margin-bottom: 20px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #d4d4d4;
  border-radius: 5px;
  font-family: Arvo, sans-serif;
  font-size: 16px;
}

.body {
  background-color: #fff;
}

.section {
  background-color: #000;
}

.div-block {
  padding-top: 30px;
}

.section-2 {
  margin-top: -30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #fff;
}

.flex-record-1 {
  display: block;
  height: auto;
  margin-top: -30px;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #fff;
}

.centered-div {
  text-align: center;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #e9e9e9;
  font-family: Arvo, sans-serif;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

.link-block.dark {
  background-color: #000;
}

._20-spacer {
  height: 20px;
}

.red-circle {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
  border-radius: 400px;
  background-color: #e70000;
}

.red-circle.in-progress {
  border-radius: 5px;
}

.icon-button-text {
  font-size: 15px;
}

.icon-button-text.blue {
  color: #45dcff;
}

.link {
  font-family: Arvo, sans-serif;
  color: #000;
  text-decoration: underline;
}

.section-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.small-icon-image {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.section-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 84vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.heading-2 {
  font-family: Oswald, sans-serif;
  color: #fff;
}

@media (max-width: 991px) {
  .qr-code {
    height: auto;
  }
  .h1 {
    line-height: 38px;
  }
  .image {
    width: 50px;
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .h1 {
    font-size: 36px;
  }
  .h2 {
    font-size: 16px;
    line-height: 24px;
  }
  .image {
    width: 40px;
    margin-right: 5px;
  }
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .form {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .link-block {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .heading {
    font-size: 32px;
  }
  .submit-button {
    border-radius: 20px;
  }
  .field-label {
    font-family: Arvo, sans-serif;
    font-size: 12px;
  }
  .text-field {
    padding-left: 0px;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    border-radius: 0px;
    font-family: Arvo, sans-serif;
    font-size: 16px;
  }
  .form-block {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .link-block {
    width: auto;
    padding-right: 80px;
    padding-left: 80px;
  }
  .section-4 {
    height: 84vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
  }
  .heading-2 {
    text-align: center;
  }
}


